.chartTitle:hover .maxIcon{
  opacity: 1 !important;
}

.maxIcon {
opacity: 0 !important;
float: right;
}

rect.disabled {
  fill: transparent !important;
}

.badgemainamount {
  font-size: 20px;
  color:'#19181A';
  opacity: '0.8';
  font-weight: bold;
}

div.ant-modal-header-custom > div.ant-modal-content {
  border-radius: 6px !important;
}
.op {
  position: relative;
  height:90px;
  width:100%;
}
.ant-table-body {
  padding-bottom: 0 !important;
}
.loader {
  content: '';
  width: 8px;
  height: 40px;
  border-radius: 4px;
  background: currentColor;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
  color: #A4CD38;
  animation: animloader 0.3s  0.45s  linear infinite alternate;
}

.loader::after, .loader::before {
  content: '';
  width: 8px;
  height: 40px;
  border-radius: 4px;
  background: currentColor;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  box-sizing: border-box;
  animation: animloader 0.3s  0.3s  linear infinite alternate;
}
.loader::before {
  left: -20px;
  animation-delay: 0s;
}


@keyframes animloader {
  0%   { height: 48px} 
  100% { height: 4px}
}
.loader {
  content: '';
  width: 8px;
  height: 40px;
  border-radius: 4px;
  background: currentColor;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
  color: #A4CD38;
  animation: animloader 0.3s  0.45s  linear infinite alternate;
}

.loader::after, .loader::before {
  content: '';
  width: 8px;
  height: 40px;
  border-radius: 4px;
  background: currentColor;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  box-sizing: border-box;
  animation: animloader 0.3s  0.3s  linear infinite alternate;
}
.loader::before {
  left: -20px;
  animation-delay: 0s;
}


@keyframes animloader {
  0%   { height: 48px} 
  100% { height: 4px}
}
.anticon svg {
  display: none !important;
}